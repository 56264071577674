<template>
  <BaseListPage locale-section="pages.provinces" route="province">
    <ProvincesList />
  </BaseListPage>
</template>

<script>
export default {
  name: "ProvincePage",
  components: {
    BaseListPage: () => import("@/components/BaseListPage"),
    ProvincesList: () => import("./ProvincesList")
  }
};
</script>
